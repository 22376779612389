<div class="d-flex drawer-wrap" [ngClass]="{'drawer-wrap--translate': showAllLocations, 'drawer-wrap--animated': animated}" cdkTrapFocus cdkTrapFocusAutoCapture>
  <app-location-list-drawer
    #locationList
    [loading]="locationsLoading"
    [locations$]="locations$"
    [title]="title"
    (dismissModal)="onDismissActiveModal()"
    (changeLocation)="goToLocationDetail(); onChangeLocation($event)"
    [class.invisible]="hideBlock === 'step1'"
  ></app-location-list-drawer>
  <ins-rollup-side-drawer
    #locationDetail
    [loading]="loading || locationsLoading"
    [drawer]="drawer"
    [title]="title"
    [fgId]="fgId"
    [tabName]="tabName"
    [getItButtonTitle]="getItButtonTitle"
    [allLocations]="allSortedLocations$ | async"
    [location]="location"
    [displayNotes]="displayNotes"
    [allItemsRare]="allItemsRare"
    [recordId]="firstAvailableRecordId"
    [isNlbCustomer]="isNlbCustomer"
    [language]="language"
    (dismissModal)="onDismissActiveModal()"
    (getItButtonClick)="onGetItButtonClick($event)"
    (changeLocation)="onChangeLocation($event)"
    (goToLocations$)="goToLocations()"
    (onDrawerSearch$)="onDrawerSearch($event)"
    [class.invisible]="hideBlock === 'step2'"
  ></ins-rollup-side-drawer>
</div>
