<div class="rollup-side-drawer">
  <div class="drawer-header-container drawer-header-container--extended
                  d-flex flex-column align-items-end justify-content-between px-2 py-1">
    <button
      #firstElement
      class="close-btn p-0 h-auto"
      (click)="onDismiss()"
      [attr.aria-label]="'closeItemsDrawer' | translate"
      data-automation-id="close-locations-drawer-btn">
      <span class="icon-close"></span>
    </button>
    <h4 class="modal-title m-0 align-self-start line-clamp line-clamp--two"
        [tippy]="title">
      {{ title }}
    </h4>
  </div>
  <div class="container filter-and-button-container d-flex align-items-center">
    <div class="row h-100">
      <div class="col-auto">
        <label id="drawer-locations-availability" class="location" translate>locationsAvailability</label>
        <div [formGroup]="searchLocationsForm" class="filter-search">
          <span class="icon-search filter-search-icon"></span>
          <input formControlName="search"
                 class="filter-search-input"
                 [attr.aria-label]="'drawerSearchLocations' | translate"
                 aria-describedby="drawer-locations-availability"
                 aria-controls="drawer-locations-availability-search-results"
                 data-automation-id="locations-search-input"
                 [placeholder]="'drawerLibraryName' | translate"/>
        </div>
      </div>
    </div>
  </div>
  <div id="drawer-locations-availability-search-results"
       class="p-6 overflow-auto drawer-wrapper">
    <div *ngIf="loading; else drawerLocations" class="d-flex justify-content-center pt-5"
         data-automation-id="drawer-locations-spinner">
      <div class="spinner-border text-primary locations-spinner" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <ng-template #drawerLocations>
      <div *ngIf="filteredLocations.available.length > 0" class="mb-10" data-automation-id="available-locations-block">
        <fa-icon aria-hidden="true" [icon]="availableIconSolid" class="icon--available mr-1"></fa-icon>
        <span class="availability-title availability-title--available" translate>onShelfAt</span>
        <ul>
          <li *ngFor="let location of filteredLocations.available" class="mt-7">
            <a
              href="" class="location-link d-flex align-items-center justify-content-between"
              (click)="$event.preventDefault(); changeLocation.emit(location)"
              data-automation-id="drawer-location-item-available">

              <span><fa-icon aria-hidden="true" [icon]="availableIcon" [attr.aria-label]="'onShelfAt' | translate"
                             class="icon icon--available mr-1"></fa-icon>
                {{ 'location.' + location.codes | translate }}</span>
              <span *ngIf="location.isPatronHome" class="home-library">
                <fa-icon [icon]="starIcon"></fa-icon>
                &nbsp;{{'homeLibrary' | translate}}
              </span>
            </a>
            <div class="shelf-cn">
              <span>{{location.callNumber}}</span>
              <span *ngIf="location.callNumber && location.itemLocationLabel"> | </span>
              <span>
                {{location.itemLocationCode ? ('location.' + location.itemLocationCode | translate)
                : location.itemLocationLabel}}
              </span>
            </div>
          </li>
        </ul>
      </div>
      <div *ngIf="filteredLocations.unavailable.length > 0" data-automation-id="unavailable-locations-block">
        <fa-icon [icon]="unavailableIconSolid" class="icon--unavailable mr-1"></fa-icon>
        <span class="availability-title availability-title--unavailable" translate>unavailableLocations</span>
        <ul>
          <li *ngFor="let location of filteredLocations.unavailable" class="mt-7">
            <a href="" class="location-link d-flex align-items-center justify-content-between"
              (click)="$event.preventDefault(); changeLocation.emit(location)"
              data-automation-id="drawer-location-item-unavailable">
              <span>
                <fa-icon aria-hidden="true" [icon]="unavailableIcon"
                         [attr.aria-label]="'unavailableLocations' | translate"
                         class="icon icon--unavailable mr-1"></fa-icon>
                {{ 'location.' + location.codes | translate }}
              </span>
              <span *ngIf="location.isPatronHome" class="home-library">
                <fa-icon [icon]="starIcon"></fa-icon>
                &nbsp;{{'homeLibrary' | translate}}
              </span>
            </a>
            <div class="shelf-cn">
              <span>{{location.callNumber}}</span>
              <span *ngIf="location.callNumber && location.itemLocationLabel"> | </span>
              <span>
                {{location.itemLocationCode ? ('location.' + location.itemLocationCode | translate)
                : location.itemLocationLabel}}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </ng-template>
  </div>
</div>

