import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as Immutable from 'immutable';
import { PersonalList } from '../../entity/models/entity';
import {
  addBookmarksComplete,
  addLoadedItems,
  changeListSort,
  clear,
  createList,
  createListComplete,
  createListFailure,
  deleteList,
  deleteListComplete,
  deleteListFailure,
  focusAvailableListCard,
  focusBookmarkButton,
  focusShareButton,
  listClosed,
  loadListItems,
  loadListItemsComplete,
  loadListItemsFailure,
  loadListsComplete,
  moveBookmarksViaSelectList,
  moveBookmarksViaSelectListDone,
  moveBookmarksViaSelectListFailure,
  openEmailModal,
  openList,
  removeBookmarksComplete,
  resetEntitiesBookmarkedState,
  resetEntityBookmarkedState,
  resetListCreatedState,
  resetListDeleteState,
  resetListUpdatedState,
  resetSelectListState,
  setAllItemsSelection,
  setBookmarkButtonsStates,
  setBookmarkButtonState,
  setDefaultListId,
  setEntitiesBookmarkedState,
  setListShowcaseRef,
  setListSort,
  stopFocusAvailableListCard,
  stopFocusBookmarkButton,
  toggleItemSelection,
  unsetListShowcaseRef,
  updateList,
  updateListComplete,
  updateListFailure,
  setSearchFacetsComplete,
  toggleBookmarksPreviewMinimized
} from '../actions/list.actions';
import { BookmarkButtonState } from '../models/bookmark-button-state';
import {
  CreateListError,
  DeleteListWithShowcaseError,
  ForLaterList,
  GetListItemsError,
  List,
  ListItem,
  ListItemEntity,
  ListSort,
  ListType,
  ListWithItemsCount,
  BookmarksSearchFacets,
  UpdateListError
} from '../models/list';
import { ListPagination } from '../models/list.dto';

export interface CreateState {
  loading: boolean;
  error: CreateListError | null;
  createdListId: string | null;
}

export interface UpdateState {
  loading: boolean;
  error: UpdateListError | null;
  updated: boolean;
}

export interface DeleteState {
  loading: boolean;
  error: DeleteListWithShowcaseError | null;
  deleted: boolean;
}

export interface ListItemsLoadingState {
  loading: boolean;
  error: GetListItemsError | null;
}

export enum SelectListState {
  INIT,
  LOADING,
  ERROR,
  DONE,
}

export interface StoredList {
  id: string;
  name: string;
  type: ListType;
  pagination: ListPagination | null;
  items: Immutable.List<ListItem>;
  itemsLoadingState: Immutable.Record<ListItemsLoadingState>;
  sort: ListSort | null;
  showcaseRef: string | null;
}

export interface StoredEntityBookmarked {
  bookmarkButtonState: BookmarkButtonState;
  personalLists: Immutable.Set<PersonalList>;
  justAddedListId: string | null;
  setOnLogin: boolean;
}

export interface EntityBookmarked {
  bookmarkButtonState: BookmarkButtonState;
  personalLists: PersonalList[];
  justAddedListId: string | null;
  setOnLogin: boolean;
}

type EntitiesBookmarked = Immutable.Map<string, Immutable.Record<StoredEntityBookmarked>>;

export type ListRecord = Immutable.Record<StoredList>;

export interface ListState {
  lists: Immutable.List<ListRecord>;
  createState: Immutable.Record<CreateState>;
  updateState: Immutable.Record<UpdateState>;
  deleteState: Immutable.Record<DeleteState>;
  openedListId: string | null;
  entitiesBookmarked: EntitiesBookmarked;
  focusBookmarkButton: string | null;
  isFocusListCardPending: boolean;
  selectListState: SelectListState;
  defaultListId: string | null;
  sharedList: ListWithItemsCount;
  focusShareButton: string | null;
  searchFacets: BookmarksSearchFacets;
  isMinimized?: boolean;
}

const CreateStateRecord = Immutable.Record<CreateState>({
  loading: false,
  error: null,
  createdListId: null,
});

const UpdateStateRecord = Immutable.Record<UpdateState>({
  loading: false,
  error: null,
  updated: false,
});

const DeleteStateRecord = Immutable.Record<DeleteState>({
  loading: false,
  error: null,
  deleted: false,
});

export const getListItemsCount = (list: List) => (list.pagination) ? list.pagination.totalResults : list.items.length;

export const isLastPageLoaded = (list: List) => (list.pagination?.page >= list.pagination?.totalPages - 1);

export const ListItemsLoadingStateRecord = Immutable.Record<ListItemsLoadingState>({
  loading: false,
  error: null,
});

export const ListRecordFactory = Immutable.Record<StoredList>({
  id: '',
  name: '',
  type: ListType.regular,
  pagination: null,
  items: Immutable.List(),
  itemsLoadingState: ListItemsLoadingStateRecord(),
  sort: null,
  showcaseRef: null,
});

export const EntityBookmarkedFactory = Immutable.Record<StoredEntityBookmarked>({
  bookmarkButtonState: BookmarkButtonState.NONE,
  personalLists: Immutable.Set(),
  justAddedListId: null,
  setOnLogin: false,
});

export const initialState: ListState = {
  lists: Immutable.List(),
  createState: CreateStateRecord(),
  updateState: UpdateStateRecord(),
  deleteState: DeleteStateRecord(),
  openedListId: null,
  entitiesBookmarked: Immutable.Map(),
  focusBookmarkButton: null,
  isFocusListCardPending: false,
  selectListState: SelectListState.INIT,
  defaultListId: null,
  sharedList: null,
  focusShareButton: null,
  searchFacets: null,
  isMinimized: false,
};

const listIdPredicate = (listId: string) => (list: ListRecord) => list.get('id') === listId;
const findSavedForLaterPredicate = (list: ListRecord) => list.get('type') === 'FOR_LATER';

const listReducer = createReducer(
  initialState,
  on(loadListsComplete, (state, {listsLoaded}) => ({
    ...state,
    lists: sortByName(Immutable.List(
      listsLoaded.map((listLoaded) => ListRecordFactory({
        ...listLoaded,
        items: Immutable.List(listLoaded.entities.map(makeListItem)),
      })),
    )),
  })),
  on(createList, (state) => ({
    ...state,
    createState: CreateStateRecord({loading: true}),
  })),
  on(createListComplete, (state, {list}) => ({
    ...state,
    lists: sortByName(state.lists.push(ListRecordFactory({
      ...list,
      pagination: {page: 0, totalResults: 0, totalPages: 0},
    }))),
    createState: CreateStateRecord({createdListId: list.id}),
  })),
  on(openEmailModal, (state, {sharedList}) => ({
    ...state,
    sharedList,
  })),
  on(focusShareButton, (state, {savedSearchId}) => ({
    ...state,
    focusShareButton: savedSearchId,
  })),
  on(createListFailure, (state, {error}) => ({
    ...state,
    createState: CreateStateRecord({error}),
  })),
  on(resetListCreatedState, (state) => ({
    ...state,
    createState: CreateStateRecord(),
  })),
  on(updateList, (state) => ({
    ...state,
    updateState: UpdateStateRecord({loading: true}),
  })),
  on(updateListComplete, (state, {listUpdate}) => ({
    ...updateListsIfFound(
      state,
      [listIdPredicate(listUpdate.id)],
      (list) => list.set('name', listUpdate.name),
      null,
      true,
    ),
    updateState: UpdateStateRecord({updated: true}),
  })),
  on(updateListFailure, (state, {id, error}) => ({
    ...state,
    updateState: UpdateStateRecord({error}),
    lists: (error.status === 404)
      ? state.lists.filter((list) => list.get('id') !== id)
      : state.lists,
  })),
  on(resetListUpdatedState, (state) => ({
    ...state,
    updateState: UpdateStateRecord(),
  })),
  on(deleteList, (state) => ({
    ...state,
    deleteState: DeleteStateRecord({loading: true}),
  })),
  on(deleteListComplete, (state, {id}) => ({
    ...state,
    deleteState: DeleteStateRecord({deleted: true}),
    lists: state.lists.filter((list) => list.get('id') !== id),
    defaultListId: state.defaultListId === id ? null : state.defaultListId,
    entitiesBookmarked: removeListFromEntitiesBookmarked(state.entitiesBookmarked, id),
  })),
  on(deleteListFailure, (state, {id, error}) => ({
    ...state,
    deleteState: DeleteStateRecord({error}),
    lists: (error.list?.status === 404)
      ? state.lists.filter((list) => list.get('id') !== id)
      : state.lists,
  })),
  on(resetListDeleteState, (state) => ({
    ...state,
    deleteState: DeleteStateRecord(),
  })),
  on(changeListSort, (state, {id, sort}) => (
    updateListsIfFound(
      state,
      [listIdPredicate(id)],
      (list) => list.set('sort', sort),
    )
  )),
  on(loadListItems, (state, {id}) => (
    updateListsIfFound(
      state,
      [listIdPredicate(id)],
      (list) => list.set('itemsLoadingState', ListItemsLoadingStateRecord({loading: true})),
    )
  )),
  on(loadListItemsComplete, (state, {id, pagination}) => (
    updateListsIfFound(
      state,
      [listIdPredicate(id)],
      (list) => list.withMutations((listWithMutations) => {
        listWithMutations
          .set('itemsLoadingState', ListItemsLoadingStateRecord())
          .set('pagination', pagination);
      }),
    )
  )),
  on(addLoadedItems, (state, {id, entities}) => (
    updateListsIfFound(
      state,
      [listIdPredicate(id)],
      (list) => list.set('items', list.get('items').push(...entities.map(makeListItem))),
    )
  )),
  on(loadListItemsFailure, (state, {id, error}) => (
    updateListsIfFound(
      state,
      [listIdPredicate(id)],
      (list) => list.set('itemsLoadingState', ListItemsLoadingStateRecord({error})),
    )
  )),
  on(openList, (state, {listId}) => ({...state, openedListId: listId})),
  on(listClosed, (state) => ({...state, openedListId: null})),
  on(addBookmarksComplete, (state, {entities, listIds}) => (
    updateListsIfFound(
      state,
      listIds.map((listId) => listIdPredicate(listId)),
      (list) => list.update('items', (items) => {
        const sort = list.get('sort');

        const mutatedItems = items.withMutations((itemsMutated) => {
          entities.forEach((entity) => {
            if (!itemsMutated.some((item) => entity.id === item.entity.id)) {
              const listItem = makeListItem(entity);

              if (sort.field === 'date' && sort.order === 'desc') {
                itemsMutated.unshift(listItem);
              } else {
                itemsMutated.push(listItem);
              }
            }
          });
        });

        if (sort.field === 'title') {
          if (sort.order === 'asc') {
            return mutatedItems.sortBy((item) => item.entity.title.toLowerCase());
          } else if (sort.order === 'desc') {
            return mutatedItems.sortBy((item) => item.entity.title.toLowerCase(), sortStringDesc);
          }
        }
        return mutatedItems;
      }),
      (listState, list) => {
        return {entitiesBookmarked: addListToEntitiesBookmarked(listState.entitiesBookmarked, list.get('id'), entities.map((e) => e.id))};
      }
    )
  )),
  on(removeBookmarksComplete, (state, {entities, listIds}) => (
    updateListsIfFound(
      state,
      listIds.map((listId) => listIdPredicate(listId)),
      (list) => list.update('items', (items) => {

        const entityIds = entities.map((entity) => entity.id);
        return items.filter((item) => !entityIds.includes(item.entity.id));
      }),
      (listState, list) => {
        return {entitiesBookmarked: removeListFromEntitiesBookmarked(listState.entitiesBookmarked, list.get('id'), entities.map((e) => e.id))};
      },
    )
  )),
  on(moveBookmarksViaSelectList, (state) => ({
    ...state,
    selectListState: SelectListState.LOADING,
  })),
  on(moveBookmarksViaSelectListDone, (state) => ({
    ...state,
    selectListState: SelectListState.DONE,
  })),
  on(moveBookmarksViaSelectListFailure, (state) => ({
    ...state,
    selectListState: SelectListState.ERROR,
  })),
  on(resetSelectListState, (state) => ({
    ...state,
    selectListState: SelectListState.INIT,
  })),
  on(toggleItemSelection, (state, {listId, itemId}) => (
    updateListsIfFound(
      state,
      [listIdPredicate(listId)],
      (list) => updateListItemIfFound(list, itemId, toggleItemSelected),
    )
  )),
  on(setAllItemsSelection, (state, {listId, selected}) => (
    updateListsIfFound(
      state,
      [listIdPredicate(listId)],
      (list) => list.update('items', (items) => setSelectedAllItems(items, selected)),
    )
  )),
  on(clear, (state, {id}) => (
    updateListsIfFound(
      state,
      [listIdPredicate(id)],
      (list) => list.update('items', (items) => clearItems(items)),
    )
  )),
  on(setBookmarkButtonState, (state, {entityId, bookmarkButtonState, justAddedListId, setOnLogin}) => ({
    ...state,
    entitiesBookmarked: state.entitiesBookmarked.update(entityId, EntityBookmarkedFactory(), (entityBookmarked) => (
      entityBookmarked
        .set('bookmarkButtonState', bookmarkButtonState)
        .set('justAddedListId', justAddedListId)
        .set('setOnLogin', setOnLogin)
    )),
  })),
  on(setBookmarkButtonsStates, (state, {entityIds, bookmarkButtonState, setOnLogin}) => ({
    ...state,
    entitiesBookmarked: state.entitiesBookmarked.withMutations((entitiesBookmarked) => {
      entityIds.forEach((entityId) => {
        entitiesBookmarked.update(entityId, EntityBookmarkedFactory(), (entityBookmarked) => (
          entityBookmarked.set('bookmarkButtonState', bookmarkButtonState).set('setOnLogin', setOnLogin)
        ));
      });
    }),
  })),
  on(resetEntityBookmarkedState, (state, {entityId}) => ({
    ...state,
    entitiesBookmarked: state.entitiesBookmarked.set(entityId, EntityBookmarkedFactory()),
  })),
  on(resetEntitiesBookmarkedState, (state, {entityIds}) => ({
    ...state,
    entitiesBookmarked: state.entitiesBookmarked.withMutations((entitiesBookmarkedWithMutations) => {
      entityIds.forEach((entityId) => {
        entitiesBookmarkedWithMutations.set(entityId, EntityBookmarkedFactory());
      });
    }),
  })),
  on(setEntitiesBookmarkedState, (state, {bookmarkedStatuses}): ListState => ({
    ...state,
    entitiesBookmarked: Immutable.Map(bookmarkedStatuses.map((bookmarkedStatus) => ([
      bookmarkedStatus.entityId,
      EntityBookmarkedFactory({
        bookmarkButtonState: bookmarkedStatus.bookmarkButtonState,
        personalLists: Immutable.Set(bookmarkedStatus.personalLists),
        justAddedListId: bookmarkedStatus.justAddedListId,
      }),
    ]))),
  })),
  on(setDefaultListId, (state, {id}): ListState => ({
    ...state,
    defaultListId: id,
  })),
  on(focusBookmarkButton, (state, {entityId}) => ({
    ...state,
    focusBookmarkButton: entityId,
  })),
  on(stopFocusBookmarkButton, (state) => ({
    ...state,
    focusBookmarkButton: null,
  })),
  on(focusAvailableListCard, (state) => ({
    ...state,
    isFocusListCardPending: true,
  })),
  on(stopFocusAvailableListCard, (state) => ({
    ...state,
    isFocusListCardPending: false,
  })),
  on(setListSort, (state, {listId, sort}) => (
    updateListsIfFound(
      state,
      [listIdPredicate(listId)],
      (list) => list.set('sort', sort),
    )
  )),
  on(setListShowcaseRef, (state, {listId, showcaseRef}) => (
    updateListsIfFound(
      state,
      [listIdPredicate(listId)],
      (list) => list.set('showcaseRef', showcaseRef),
    )
  )),
  on(unsetListShowcaseRef, (state, {id}) => (
    updateListsIfFound(
      state,
      [listIdPredicate(id)],
      (list) => list.set('showcaseRef', null),
    )
  )),
  on(setSearchFacetsComplete, (state, {searchFacets}) => ({
    ...state,
    searchFacets,
  })),
  on(toggleBookmarksPreviewMinimized, (state) => ({
    ...state,
    isMinimized: !state.isMinimized
  })),
);

export function reducer(state: ListState | undefined, action: Action) {
  return listReducer(state, action);
}

export const listFeatureKey = 'list';

export const getListState = createFeatureSelector<ListState>(listFeatureKey);

export const selectBookmarksPreviewMinimized = createSelector(getListState, (listState) => listState?.isMinimized);

export const getLists = createSelector(getListState, (state: ListState): ListWithItemsCount[] | null => {
  return (state && state.lists) ? (state.lists.toJS() as ListWithItemsCount[]).map((list) => ({
    ...list,
    itemsCount: getListItemsCount(list)
  })) : null;
});

export const getForLaterList = createSelector(getListState, (state: ListState): ForLaterList | null => {
  const foundList = state.lists.find(findSavedForLaterPredicate);
  return (foundList) ? (foundList.toJS() as ForLaterList) : null;
});

export const sharedList = createSelector(getListState, (state: ListState): ListWithItemsCount => {
  return state.sharedList;
});

export const getFocusShareButton = createSelector(getListState, (state): string | null => {
  return (state) ? state.focusShareButton : null;
});

export const getDefaultList = createSelector(getListState, (state: ListState): List | null => {
  const defaultListPredicate = state.defaultListId ? listIdPredicate(state.defaultListId) : findSavedForLaterPredicate;
  const foundList = state.lists.find(defaultListPredicate);
  return (foundList) ? (foundList.toJS() as List) : null;
});

export const getList = createSelector(getListState, (state: ListState, props: { id: string }): List | null => {
  const foundList = state.lists.find(listIdPredicate(props.id));
  return (foundList) ? (foundList.toJS() as List) : null;
});

export const getListItems = createSelector(getListState, (state: ListState, props: { id: string }): ListItem[] | null => {
  const foundList = state.lists.find(listIdPredicate(props.id));
  return (foundList) ? (foundList.toJS() as List).items : null;
});

export const getListPagination = createSelector(getListState, (state: ListState, props: { id: string }): ListPagination | null => {
  const foundList = state.lists.find(listIdPredicate(props.id));
  return (foundList) ? (foundList.toJS() as List).pagination : null;
});

export const getListItemsLoadingState = createSelector(getListState, (state: ListState, props: { id: string }): ListItemsLoadingState | null => {
  const foundList = state.lists.find(listIdPredicate(props.id));
  return (foundList) ? (foundList.toJS() as List).itemsLoadingState : null;
});

export const getListSort = createSelector(getListState, (state: ListState, props: { id: string }): ListSort | null => {
  const foundList = state.lists.find(listIdPredicate(props.id));
  return (foundList) ? (foundList.toJS() as StoredList).sort : null;
});

export const getSelectedListItemEntities = createSelector(
  getListState,
  (state: ListState, props: { id: string }): ListItemEntity[] | null => {
    const list = state.lists.find(listIdPredicate(props.id));
    return (list)
      ? list.get('items').filter((item) => item.selected).map((item) => item.entity).toJS() as ListItemEntity[]
      : null;
  },
);

export const getCreateState = createSelector(getListState, (state): CreateState => {
  return (state.createState.toJS() as CreateState);
});

export const getUpdateState = createSelector(getListState, (state): UpdateState | null => {
  return (state && state.updateState) ? (state.updateState.toJS() as UpdateState) : null;
});

export const getDeleteState = createSelector(getListState, (state): DeleteState => {
  return state.deleteState.toJS();
});

export const getOpenedList = createSelector(getListState, (state: ListState): ListWithItemsCount | null => {
  if (state && state.openedListId && state.lists) {
    const foundList = state.lists.find((list) => list.get('id') === state.openedListId);
    if (foundList) {
      const list = foundList.toJS() as List;
      return {...list, itemsCount: getListItemsCount(list)};
    }
  }

  return null;
});

export const getSelectListState = createSelector(getListState, (listState: ListState): SelectListState => {
  return listState.selectListState;
});

export const getEntitiesBookmarked = createSelector(getListState, (state: ListState): Record<string, EntityBookmarked> => {
  return state.entitiesBookmarked.toJS() as Record<string, EntityBookmarked>;
});

export const getEntityBookmarked = createSelector(getListState, (state: ListState, props: { id: string }): EntityBookmarked | null => {
  const entityBookmarked = state.entitiesBookmarked.get(props.id);
  return (entityBookmarked) ? (entityBookmarked.toJS() as EntityBookmarked) : null;
});

export const getFocusBookmarkButton = createSelector(getListState, (state: ListState): string | null => {
  return state.focusBookmarkButton;
});

export const getIsFocusListCardPending = createSelector(getListState, (state: ListState): boolean => {
  return state.isFocusListCardPending;
});

export const getSearchFacets = createSelector(getListState, (state: ListState): BookmarksSearchFacets => {
  return state.searchFacets;
});

export const removeFromItems = (items: Immutable.List<ListItem>, entity: ListItemEntity) => {
  return items.filter((item) => item.entity.id !== entity.id);
};

export const removeSelectedItems = (items: Immutable.List<ListItem>) => items.filter((item) => !item.selected);

export const setSelectedAllItems = (items: Immutable.List<ListItem>, selected: boolean) => (
  items.map((item) => ({
    ...item,
    selected,
  }))
);

export const clearItems = (items: Immutable.List<ListItem>) => (
  items.clear()
);

export const toggleItemSelected = (item: ListItem): ListItem => ({
  ...item,
  selected: !item.selected,
});

export const makeListItem = (entity: ListItemEntity) => ({
  id: Math.random().toString().slice(2),
  selected: false,
  entity
});

export const updateListItemIfFound = (
  list: ListRecord,
  itemId: string,
  callback: (item: ListItem) => ListItem,
): ListRecord => {
  const foundIndex = list.get('items').findIndex((item) => item.id === itemId);
  if (foundIndex >= 0) {
    return list.updateIn(['items', foundIndex], callback);
  }

  return list;
};

const updateListsIfFound = (
  state: ListState,
  listPredicates: ((list: ListRecord) => boolean)[],
  callback: (list: ListRecord) => ListRecord,
  stateCallback?: (state: ListState, list: ListRecord) => Partial<ListState>,
  sortNeeded = false,
): ListState => {
  let newState = state;
  let someListMutated = false;

  const listsMutated = state.lists.withMutations((lists) => {
    listPredicates.forEach((predicate) => {
      const listIndex = lists.findIndex(predicate);

      if (listIndex >= 0) {
        someListMutated = true;
        lists = lists.updateIn([listIndex], callback);
        if (stateCallback) {
          newState = {...newState, ...stateCallback(newState, lists.get(listIndex))};
        }
      }
    });
  });

  if (someListMutated) {
    return {
      ...newState,
      lists: sortNeeded ? sortByName(listsMutated) : listsMutated,
    };
  }

  return newState;
};

const addListToEntitiesBookmarked = (
  entitiesBookmarked: EntitiesBookmarked,
  listId: string,
  entityIds: string[],
): EntitiesBookmarked => {

  return entitiesBookmarked.withMutations((entitiesBookmarkedMutations) => {
    entityIds.forEach((entityId) => {
      entitiesBookmarkedMutations.update(entityId, EntityBookmarkedFactory(), (entityBookmarked) => {
        const list = entityBookmarked.get('personalLists').find(list => list.recordId === entityId || list.id === entityId) || {
          recordId: entityId,
          listIds: []
        };
        list.listIds.push(listId);

        return EntityBookmarkedFactory({
          ...entityBookmarked.toJS(),
          personalLists: entityBookmarked.get('personalLists').add(list),
        });
      });
    });
  });
};

const removeListFromEntitiesBookmarked = (
  entitiesBookmarked: EntitiesBookmarked,
  listId: string,
  entityIds?: string[],
): EntitiesBookmarked => {
  if (!entityIds) {
    entityIds = [...entitiesBookmarked.keys()];
  }

  return entitiesBookmarked.withMutations((entitiesBookmarkedMutations) => {
    entityIds.forEach((entityId) => {
      entitiesBookmarkedMutations.update(entityId, EntityBookmarkedFactory(), (entityBookmarked) => {
        const list = entityBookmarked.get('personalLists').filter(list => list.recordId !== entityId || list.id !== entityId);
        const updatedList = list.map(list => {
          return {...list, listIds: list.listIds.filter(id => id !== listId)};
        });
        const notEmptyLists = updatedList.filter(list => list.listIds.length);

        return EntityBookmarkedFactory({
          ...entityBookmarked.toJS(),
          personalLists: notEmptyLists
        });
      });
    });
  });
};

const sortByName = (lists: Immutable.List<ListRecord>): Immutable.List<ListRecord> => {
  return lists.sortBy((list) => list.toJS().name.toLowerCase());
};

const sortStringDesc = (aString: string, bString: string) => {
  if (aString > bString) {
    return -1;
  }
  if (aString < bString) {
    return 1;
  }
  return 0;
};
