import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { LocationWithName, PickupArea } from 'app/models/locations';
import { Observable, Subject, Subscription } from 'rxjs';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { IlsType } from 'shared/models/ilsType';
import { loadTabHold, updateShouldCloseModal } from 'user/actions/user-profile.actions';
import { GetItForm, HoldNeededDateForm } from 'user/models/get-it';
import { BorrowByMailConfiguration, UserAddress } from 'user/models/user';
import { getErrorMessage, getShouldCloseModal, isLoading, UserState } from 'user/reducers/user.reducer';
import { CustomerFeature } from '../../../customer-integration/customer-integration';
import { FeatureToggleService } from 'app/services/feature-toggle.service';
import { PickupLocationService } from 'user/services/pickup-location.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-get-it-dialog',
  templateUrl: './get-it-dialog.component.html',
  styleUrls: ['./get-it-dialog.component.scss'],
  animations: [
    trigger('collapsible', [
      state('void', style({ height: '0' })),
      state('*', style({ height: '*' })),
      transition('* <=> *', animate('200ms')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GetItDialogComponent implements OnInit {
  @Input() public pickupLocations: LocationWithName[];
  @Input() public selectedLocationCode: string;

  @Input() public availablePickupArea: PickupArea[];
  @Input() public selectedPickupAreaCode?: string;

  @Input() public volume: string;
  @Input() public itemVolume: string;
  @Input() public borrowByMailConfiguration?: BorrowByMailConfiguration;
  @Input() public holdExpirationDefault: number;
  @Input() public holdActivationDateSetting: boolean;
  @Input() public userAddress: UserAddress;
  @Input() public errorMessage: string;

  public getItForm: FormGroup<GetItForm>;
  public ilsType: IlsType;
  public formDataSubject = new Subject<any>();
  public isLoading$: Observable<boolean>;
  public isPickupAreaFeatureEnabled: boolean;
  public defaultPickupArea: PickupArea;
  public shouldValidatePickupAreas: boolean;
  public readonly IlsType = IlsType;
  public readonly CustomerFeature = CustomerFeature;
  private readonly subscription = new Subscription();

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly fb: FormBuilder,
    private readonly store: Store<UserState>,
    private readonly configLoader: ConfigurationLoader,
    private readonly featureToggleService: FeatureToggleService,
    private readonly pickupLocationService: PickupLocationService,
    private readonly cd : ChangeDetectorRef,
    private readonly translateService: TranslateService,
  ) {}

  public ngOnInit() {
    const defaultOptionLabel = this.translateService.instant('selectPickupAreaPlaceholder');
    this.defaultPickupArea = {
      code: '',
      description: defaultOptionLabel,
      parentCode: '',
      selected: false,
    };
    this.isPickupAreaFeatureEnabled = this.featureToggleService.getToggles()['VDIS-30375_2024-12-31_pickup-area'];
    this.getItForm = this.fb.group<GetItForm>({
      pickupLocation: this.fb.control<string>(this.selectedLocationCode, Validators.required),
      pickupArea: this.fb.control<string>(this.selectedPickupAreaCode || this.defaultPickupArea.code),
      borrowByMail: this.fb.control<boolean>(false),
      holdNeededDate: this.fb.group<HoldNeededDateForm>({
        hasExpirationDate: this.fb.control<boolean>(false),
      }),
    });

    this.ilsType = this.configLoader.ilsType;
    this.isLoading$ = this.store.select(isLoading);

    if (!this.pickupLocations?.length) {
      this.pickupLocation.disable();
    }

    this.handlePickupAreaSelection();
  }

  public changePickupLocation(code: string) {
    // neeed because the form control retains the old value,
    // even though the dropdown options are updated.
    if (this.isPickupAreaFeatureEnabled && this.ilsType === IlsType.polaris) {
      const pickupAreaControl = this.getItForm.get('pickupArea');
      pickupAreaControl?.reset();
      this.pickupLocationService.getUserPickupArea(code).subscribe((updatedArea) => {
        const isNoneAnOption = this.isNoneInPickUpAreaOptions(updatedArea);
        this.updatePickupAreaOptions(updatedArea, isNoneAnOption);

        if (this.availablePickupArea.length) {
          // set empty value by default every time location code changed
          pickupAreaControl?.setValue(this.availablePickupArea[0].code);
          this.shouldValidatePickupAreas = this.availablePickupArea.length > 1;
        }
        this.cd.detectChanges();
      });
    }
  }

  public onClose() {
    this.formDataSubject.error({});
    this.store.dispatch(updateShouldCloseModal({ errorMessage: '' }));
    this.activeModal.dismiss();
  }

  public onSubmit() {
    this.store.dispatch(loadTabHold());
    this.formDataSubject.next(this.getItForm.getRawValue());
    this.subscription.add(
      this.store.select(getShouldCloseModal).subscribe((shouldCloseModal) => {
        if (shouldCloseModal) {
          this.activeModal.close();
        }
      }),
    );

    this.subscription.add(
      this.store.select(getErrorMessage).subscribe((message) => {
        if (message) {
          this.errorMessage = message;
        }
      }),
    );
  }

  public onBorrowByMailChange(checked: boolean) {
    this.getItForm.patchValue({
      borrowByMail: checked,
    });
    if (checked) {
      this.shouldValidatePickupAreas = false;
      this.pickupLocation.disable();
      this.pickupArea.disable();
    } else {
      this.shouldValidatePickupAreas = true;
      this.pickupLocation.enable();
      this.pickupArea.enable();
    }
  }

  public get borrowByMail(): AbstractControl {
    return this.getItForm.get('borrowByMail');
  }

  public get pickupLocation(): AbstractControl {
    return this.getItForm.get('pickupLocation');
  }

  public get pickupArea(): AbstractControl {
    return this.getItForm.get('pickupArea');
  }

  public isNoneInPickUpAreaOptions(pickupAreas: PickupArea[]): boolean {
    return pickupAreas.some((area) => area.description.toLowerCase().includes('none'));
  }

  public updatePickupAreaOptions(pickupAreas: PickupArea[], isNoneAnOption: boolean): void {
    this.availablePickupArea = isNoneAnOption ? [...pickupAreas] : [this.defaultPickupArea, ...pickupAreas];
  }

  public handlePickupAreaSelection(): void {
    if (this.selectedPickupAreaCode === '') {
      const isNoneAnOption = this.isNoneInPickUpAreaOptions(this.availablePickupArea);
      if (isNoneAnOption && this.ilsType === IlsType.polaris) {
        const noneOption = this.availablePickupArea.find(area => area.description.toLowerCase().includes('none'));
        this.pickupArea.setValue(noneOption.code);
      } else {
        this.availablePickupArea.unshift(this.defaultPickupArea);
      }
      this.shouldValidatePickupAreas = this.availablePickupArea.length > 1;
      this.cd.detectChanges();
    }
  }
}
