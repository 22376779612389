<header id="header-config" >
  <div *ngIf="headerConfig" class="header">
    <ng-container *ngIf="!headerConfig.customHeader?.enabled || stopCustom; else customHeader">
      <app-global-header
        [url]="url"
        [title]="headerConfig.title"
        [logo]="logo"
        (onResetFacets)="resetFacets()"
        (skipTo)="skipToElement($event)"
      ></app-global-header>
    </ng-container>
    <ng-template #customHeader>
      <app-custom-section [code]="headerConfig.customHeader.code"></app-custom-section>
    </ng-template>

    <app-global-nav [links]="headerConfig.links"></app-global-nav>
  </div>
</header>
