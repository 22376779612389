<div class="rollup-side-drawer overflow-auto">
  <div
    class="drawer-header-container drawer-header-container--extended d-flex flex-column justify-content-between px-2 py-1">
    <div class="d-flex align-items-end justify-content-between">
      <a class="d-flex p-4 go-to-locations"
         href=""
         #firstElement
         data-automation-id="go-to-locations"
         (click)="$event.preventDefault(); goToLocations$.emit()">
        <fa-icon [icon]="goToLocationsIcon" class="mr-1"></fa-icon>
        <span translate>locationsLink</span>
      </a>
      <button
        class="close-btn p-0 h-auto"
        (click)="onDismiss()"
        [attr.aria-label]="'closeItemsDrawer' | translate"
        data-automation-id="close-drawer-btn">
        <span class="icon-close"></span>
      </button>
    </div>
    <h4 class="modal-title m-0 line-clamp line-clamp--two"
        [tippy]="title"
        id="drawer-items-title"
        data-automation-id="drawer-items-title">
      {{ title }}
    </h4>
  </div>
  <div class="container filter-and-button-container">
    <div class="row h-100 justify-content-between">
      <div class="col-auto d-flex flex-column filter-dropdown">
        <label class="location" for="location-select" translate data-automation-id="drawer-locations-label">locationDrawer</label>
        <select class="filter-location-select pr-9" id="location-select" data-automation-id="drawer-locations-select"
                (change)="onChangeLocation($event.target.value)" [disabled]="allLocations?.length < 2">
          <option *ngFor="let loc of allLocations;" [value]="loc.label" [selected]="loc.label === location?.label"
                  data-automation-id="location-drawer-option">
            {{ 'location.' + loc.codes | translate }}
          </option>
        </select>
      </div>
      <div *ngIf="!allItemsRare" class="col-auto d-flex justify-content-end align-items-center">
        <ng-container *ngIf="!!getItButtonTitle">
          <button class="ins-buttons button-primary flint mt-3"
                    (click)="onGetIt($event)"
                    data-automation-id="get-it-drawer-btn">{{getItButtonTitle}}</button>
        </ng-container>
      </div>
    </div>
    <div *ngIf="recordId && isNlbCustomer" class="row">
      <div class="qr-code-container col-auto mb-6 ml-7 mt-2 mt-sm-0">
        <app-nlb-qr-code
          [recordId]="recordId"
          [isElectronic]="false"
          [size]="'6'"
        ></app-nlb-qr-code>
      </div>
    </div>
  </div>
  <div class="modal-body drawer-body p-0">
    <div class="d-flex flex-row align-items-center w-100 px-4 py-5" data-automation-id="drawer-search-bar">
      <fa-icon [icon]="searchIcon" class="position-absolute mx-1"></fa-icon>
      <input class="w-100 px-5 m-0"
             #drawerSearchInput
             [attr.aria-label]="'searchEditionsOnLocationLabel' | translate">
    </div>
    <div class="container p-0 drawer-container drawer-container--extended"
         [ngClass]="(recordId && isNlbCustomer) ? 'drawer-container--extended-with-qr-code' : 'drawer-container--extended'">
      <div *ngIf="loading; else drawerItems" class="d-flex justify-content-center pt-5" data-automation-id="drawer-items-spinner">
        <div class="spinner-border text-primary" style="width: 7rem; height: 7rem;" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <ng-template #drawerItems>
        <section role="list">
          <ng-container *ngFor="let holdingInfo of drawer.holdings; index as i; last as isLast">
            <app-drawer-holdings [isLast]="isLast" [holdings]="holdingInfo"></app-drawer-holdings>
          </ng-container>
        </section>

        <hr *ngIf="drawer.items?.length && drawer.holdings?.length" class="m-0 mt-4"/>

        <section role="list">
          <ng-container *ngFor="let itemInfo of drawer.items; index as i; last as isLast">
            <app-drawer-item
              [item]="itemInfo"
              [displayNotes]="displayNotes"
              [isNlbCustomer]="isNlbCustomer"
              [title]="title"
              [isLast]="isLast"
              [recordId]="recordId"
              [location]="location"
              [language]="language">
            </app-drawer-item>
          </ng-container>
        </section>
      </ng-template>
    </div>
  </div>

</div>
