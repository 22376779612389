import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { faArrowRight, faCartShoppingFast, faArrowDownLeftAndArrowUpRightToCenter, faX } from '@fortawesome/pro-solid-svg-icons';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { tryToBulkHoldSelectedItems } from '../../../bulk-select/actions/bulk-select.actions';
import { BulkFeatureKey } from '../../../bulk-select/enums/bulk-feature-key';
import { CustomerFeature } from '../../../customer-integration/customer-integration';
import { ListItem } from '../../models/list';
import { EntityTypes } from 'app/entity/models/entity';
import { RollUpTab } from 'app/entity/models/rollup-card';
import { rollupTabSelected } from 'app/entity/actions/entity.actions';
import { collapseBookshelf } from 'user/actions/user-profile.actions';
import { selectBookmarksPreviewMinimized } from 'app/list/reducers/list.reducer';
import { toggleBookmarksPreviewMinimized } from 'app/list/actions/list.actions';

export interface Icon {
  url: string,
  filter: string,
  label: string,
  formatGroup?: string
}
export type ListItemWithIcons = ListItem & {icons?: Icon[]}

@Component({
  selector: 'app-searchable-bookmarks-results-preview',
  templateUrl: './searchable-bookmarks-results-preview.component.html',
  styleUrls: ['./searchable-bookmarks-results-preview.component.scss']
})
export class SearchableBookmarksResultsPreviewComponent implements OnInit, OnDestroy {
  @Input() public preview: ListItem[] = [];
  @Input() loading: boolean = false;
  @Output() public openList = new EventEmitter();
  public itemsSelectedCount: number;
  public isAllSelected: boolean;
  public placeHoldsIcon = faCartShoppingFast;
  public minimizeIcon = faArrowDownLeftAndArrowUpRightToCenter;
  public closeIcon = faX;
  public isCollapsed: boolean = false;
  public linkIcon = faArrowRight;
  public bulkFeatureKey = BulkFeatureKey.LIBRARY_LIST;
  public readonly CustomerFeature = CustomerFeature;
  public isMinimized$ = this.store.select(selectBookmarksPreviewMinimized);

  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly store: Store,
  ) {
  }

  ngOnInit() {
    this.itemsSelectedCount = (this.preview ?? []).filter(item => item.selected).length;
  }

  public onNavigation(
    id: string,
    entityType: EntityTypes,
    tabInfo?: RollUpTab,
  ) {
    if (tabInfo) {
      this.store.dispatch(rollupTabSelected(tabInfo));
    }
    this.store.dispatch(collapseBookshelf());
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  toggleMinimize() {
    this.store.dispatch(toggleBookmarksPreviewMinimized());
  }

  toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed;
  }

  public setAllItemsSelection(selected: boolean) {
    this.isAllSelected = !this.isAllSelected;
    this.preview.forEach(item => item.selected = this.isAllSelected);
    this.itemsSelectedCount = this.preview.filter(item => item.selected).length;
  }

  public bulkPlaceHolds(event: Event) {
    event.stopPropagation();
    const items = this.preview.filter(item => item.selected).map((item) => item.entity.sourceEntity);
    this.store.dispatch(tryToBulkHoldSelectedItems({bulkFeatureKey: this.bulkFeatureKey, items}));
  }

  public onItemSelectChange(itemId: string, checked: boolean) {
    const item = this.preview.find(item => item.id === itemId);
    item.selected = checked;
    if (this.isAllSelected && !checked) {
      this.isAllSelected = false;
    }
    if (!this.preview.find(item => !item.selected)) {
      this.isAllSelected = true;
    }
    this.itemsSelectedCount = this.preview.filter(item => item.selected).length;
  }
}
