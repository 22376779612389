import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { BookshelfItem } from 'common/models/bookshelf-card';
import { collapseBookshelf } from 'user/actions/user-profile.actions';
import { rollupTabSelected } from '../../../entity/actions/entity.actions';
import {
  AvailabilityStatus,
  AvailabilityUpdateInfo,
  EntityTypes,
  FormatGroup,
  MaterialTab,
} from '../../../entity/models/entity';
import { RollUpTab } from '../../../entity/models/rollup-card';
import {
  ShareItResource,
  ShareItShareEntityType,
} from '../../../share-it/models/share-it';
import { DiscoverV2BffService } from 'app/elements-v2/service/discover-v2-bff.service';
import { Subscription } from 'rxjs';
import { DictionaryItem, DictionaryTypes } from 'app/models/dictionaries';
import { DictionariesService } from 'app/services/dictionaries.service';
import {
  AvailabilityStatusToFilterMap,
  MATERIAL_ICONS,
} from 'app/models/material-icons';
import { WindowRefService } from '../../../services/window-ref.service';
import { Icon } from 'app/list/components/searchable-bookmarks-results-preview/searchable-bookmarks-results-preview.component';

import { ShareOptions } from '../../../models/MetaData';
import { truncateString } from 'core/utils/string-helper';

@Component({
  selector: 'app-bookshelf-item-card',
  templateUrl: './bookshelf-item-card.component.html',
  styleUrls: ['./bookshelf-item-card.component.scss'],
})
export class BookshelfItemCardComponent implements OnInit {
  @Input() public item: BookshelfItem;
  @Input() public listId?: string;
  @Input() public isExtendedCard: boolean;
  // todo: remove; it's a temporary solution for hiding checkbox in RH
  @Input() public hideCheckbox: boolean;
  @Output() public onToggle = new EventEmitter();
  @Output() public cardNavigation = new EventEmitter();

  public format: string = null;
  public ariaTitleId: string;
  public shareData: ShareOptions;
  public entityTypes = EntityTypes;
  public resourceData: ShareItResource;
  public showCoverImage = false;
  public bookshelfShowShareIcon: boolean;
  public materialTabs: Icon[];
  private readonly subscriptions = new Subscription();

  constructor(
    public store: Store,
    private discoverV2BffService: DiscoverV2BffService,
    private readonly dictionariesService: DictionariesService,
    private readonly windowRefService: WindowRefService
  ) {}

  public ngOnInit() {
    this.materialTabs = this.getIcons(this.item?.entity?.sourceEntity?.materialTabs as MaterialTab[]);
    this.resourceData = this.prepopulateDataForSharing();
    this.ariaTitleId = `ariaLabel-${this.item.id}`;
    this.showCoverImage =
      this.item.entity.entityType === this.entityTypes.FORMAT_GROUP ||
      this.item.entity.entityType === this.entityTypes.AGENT;
    this.subscriptions.add(
      this.discoverV2BffService.$page.subscribe((page) => {
        if (page) {
          this.bookshelfShowShareIcon =
            page.settings?.social_media_display?.my_bookshelf;
        }
      }));

    this.shareTheLink();
  }
  public toggleCardSelection() {
    this.onToggle.emit();
  }

  public prepopulateDataForSharing(): ShareItResource {
    const { id, entityType, title, primaryAgent, editionsData } =
      this.item.entity;

    return {
      type: ShareItShareEntityType.RESOURCE,
      attributes: {
        id,
        url: this.getUrlForResourceData(this.item),
        title,
        author: primaryAgent?.label || '',
        publicationDate: editionsData?.[0]?.publicationDate || '',
        resourceType: entityType,
      },
    };
  }

  public onNavigation(
    id: string,
    entityType: EntityTypes,
    tabInfo?: RollUpTab,
  ) {
    if (tabInfo) {
      this.store.dispatch(rollupTabSelected(tabInfo));
    }
    this.store.dispatch(collapseBookshelf());
  }

  private getUrlForResourceData(item: BookshelfItem): string {
    if (item.entity.entityType === EntityTypes.FORMAT_GROUP) {
      const recordId =
        item.entity.personalLists?.find((list) =>
          list.listIds.includes(this.listId),
        )?.recordId || item.entity.editionsData?.[0]?.recordId;

      return `/search/card?recordId=${this.item.entity.selectedTabRecordId || recordId}`;
    } else {
      return `/search/card?id=${item.entity.id}&entityType=${item.entity.entityType}`;
    }
  }

  private getIcons(materialTabs: MaterialTab[]) {
    const icons: Icon[] = [];
    materialTabs?.slice(0, 4).forEach((m, index) => {
      if (m?.availability?.status) {
        const code = m.materialTypes?.[0];
        const item: DictionaryItem = this.dictionariesService.getDictionaryItemByCode(
          DictionaryTypes.MATERIAL_TYPES,
          code
        );
        const iconKey = materialTabs.length > 4 && index === 3
          ? 'default-other'
          : item?.icon ?? 'default-other';
        const iconDetails = MATERIAL_ICONS[iconKey as string];
        const url = iconDetails?.icon || '';
        const label = iconDetails?.label || '';
        const formatGroup = item?.formatGroup;
        const availabilityStatus = m.availability.status.general ?? AvailabilityStatus.UNAVAILABLE;
        const filter = AvailabilityStatusToFilterMap[availabilityStatus];
        icons.push({url, filter, label, formatGroup});
      }
    });
    return icons;
  }

  public shareTheLink() {
    const description = '';
    const image = this.item.entity.coverConfig?.coverUrl?.['large']
      || this.item.entity.coverConfig?.coverUrl?.['medium']
      || this.item.entity.coverConfig?.coverUrl?.['small'];
    const {id, title, entityType} = this.item.entity;

    const path = `/search/card?id=${id}&entityType=${entityType}`;
    const origin = this.windowRefService.origin();

    this.shareData = {
      id,
      title: encodeURIComponent(truncateString(title, 50)),
      description: encodeURIComponent(description),
      image,
      url: encodeURIComponent(origin+path)
    };
  }
}
