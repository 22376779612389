import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { Drawer, DrawerElement, GroupedLocationInfoAvailability } from 'search/models/search-models';
import { GroupedLocationInfo, LocationAvailabilityStatus } from 'search/models/search-results';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { areEqualDeeply } from 'shared/utils/are-equal-deeply';
import { AvailabilityStatuses } from '../../../models/dictionaries';
import { State } from '../../../reducers';
import { drawerInformationLoad, loadLocations, queryDrawerContent } from '../../actions/entity.actions';
import { getDrawerLocations, getQueriedDrawer } from '../../reducers/entity.reducer';
import { LocationListDrawerComponent } from './locations-list-drawer/location-list-drawer.component';
import { RollupSideDrawerComponent } from './rollup-side-drawer/rollup-side-drawer.component';

type DrawerHideBlock = 'step1' | 'step2';

@Component({
  selector: 'ins-rollup-side-drawer-container',
  templateUrl: './rollup-side-drawer-container.component.html',
  styleUrls: ['./rollup-side-drawer-container.component.scss'],
})
export class RollupSideDrawerContainer implements OnInit, OnDestroy {

  @Input() public fgId: string;
  @Input() public tabName: string;
  @Input() public title: string;
  @Input() public getItButtonTitle: string;
  @Input() public location: GroupedLocationInfo;
  @Input() public showAllLocations: boolean;
  @Input() public hideBlock: DrawerHideBlock | null;
  @Input() public isNlbCustomer: boolean;
  @Input() public language: string;
  @ViewChild('locationList') public locationList: LocationListDrawerComponent;
  @ViewChild('locationDetail') public locationDetail: RollupSideDrawerComponent;

  public animated = false;
  public drawer: Drawer;
  public loading = false;
  public displayNotes: boolean;
  public locationsLoading = false;
  public locations$: Observable<GroupedLocationInfoAvailability>;
  public allSortedLocations$: Observable<GroupedLocationInfo[]>;
  public firstAvailableRecordId: string;
  public allItemsRare: boolean;

  private subscriptions = new Subscription();

  public constructor(
    private readonly store: Store<State>,
    private readonly modal: NgbActiveModal,
    private readonly configLoader: ConfigurationLoader,
  ) {
  }

  public ngOnInit(): void {
    this.store.dispatch(loadLocations({formatGroupId: this.fgId, tabName: this.tabName}));
    this.displayNotes = this.configLoader.displayConfiguration.displayNotes;

    if (!this.showAllLocations) {
      this.store.dispatch(drawerInformationLoad({
        formatGroupId: this.fgId,
        tabName: this.tabName,
        selectedLocationCodes: this.location.codes,
      }));
    }

    this.subscriptions.add(
      this.store.pipe(select(getQueriedDrawer))
      .pipe(distinctUntilChanged(areEqualDeeply))
      .subscribe(({content, loading}) => {
        this.loading = loading;
        this.drawer = content || {items: []};
        const firstAvailableItem = this.findFirstAvailableItem();
        this.firstAvailableRecordId = firstAvailableItem?.editionRecordId;
        this.allItemsRare = this.drawer.items.every((item) => !!item.rareContentLink);
      }),
    );

    this.locations$ = this.store.pipe(select(getDrawerLocations)).pipe(
      filter((locationResults) => !!locationResults.content),
      map(({content}) => ({
        available: content.available.map(({ label, code, isPatronHome, itemLocationLabel, itemLocationCode, callNumber }) => ({
          label,
          codes: [code],
          isPatronHome,
          itemLocationLabel,
          itemLocationCode,
          callNumber,
          availabilityStatus: LocationAvailabilityStatus.Available,
        })),
        unavailable: content.unavailable.map(({ label, code, isPatronHome, itemLocationLabel, itemLocationCode , callNumber }) => ({
          label,
          codes: [code],
          isPatronHome,
          itemLocationLabel,
          itemLocationCode,
          callNumber,
          availabilityStatus: LocationAvailabilityStatus.Unavailable,
        })),
      }),
    ));

    this.allSortedLocations$ = this.store.pipe(select(getDrawerLocations)).pipe(
      filter((locationResults) => !!locationResults.content),
      map((locationResults) => {
        return [
          ...locationResults.content.available.map(({ label, code, isPatronHome ,itemLocationLabel, itemLocationCode, callNumber }) => ({
            label,
            codes: [code],
            isPatronHome,
            itemLocationLabel,
            itemLocationCode,
            callNumber,
            availabilityStatus: LocationAvailabilityStatus.Available,
          })),
          ...locationResults.content.unavailable.map(({label, code, isPatronHome, itemLocationLabel, itemLocationCode, callNumber }) => ({
            label,
            codes: [code],
            isPatronHome,
            itemLocationLabel,
            itemLocationCode,
            callNumber,
            availabilityStatus: LocationAvailabilityStatus.Unavailable,
          })),
        ].sort((a, b) => a.label.toLocaleLowerCase() > b.label.toLocaleLowerCase() ? 1 : -1);
      }),
      startWith([]),
    );

    this.subscriptions.add(
      this.store.pipe(select(getDrawerLocations)).subscribe(({loading}) => {
        this.locationsLoading = loading;
      }),
    );
  }

  public onDismissActiveModal() {
    this.modal.dismiss();
  }

  public onGetItButtonClick(event: Event) {
    event.stopPropagation();
    this.modal.close('getIt');
  }

  public onChangeLocation(value: GroupedLocationInfo) {
    this.location = value;
    this.store.dispatch(drawerInformationLoad({formatGroupId: this.fgId, tabName: this.tabName, selectedLocationCodes: value.codes}));
  }

  public goToLocations(): void {
    this.hideBlock = null;
    this.showAllLocations = true;
    this.animated = true;

    setTimeout(() => {
      this.hideBlock = 'step2';
      this.locationList?.focusFirstElement();
    }, 1000);
  }

  public goToLocationDetail(): void {
    this.hideBlock = null;
    this.showAllLocations = false;
    this.animated = true;

    setTimeout(() => {
      this.hideBlock = 'step1';
      this.locationDetail?.focusFirstElement();
    }, 1000);
  }

  public onDrawerSearch(query: string): void {
    this.store.dispatch(queryDrawerContent({query}));
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private findFirstAvailableItem(): DrawerElement | undefined {
    const firstAvailable = this.drawer.items.find((item) => item.status.availabilityStatus === AvailabilityStatuses.AVAILABLE);
    return firstAvailable ?? this.drawer.items[0];
  }
}
