<div class="change-passcode-dialog-container p-4">
  <div class="modal-header">
    <h2 class="modal-title m-0" data-automation-id="change-passcode-dialog-title">
      {{'changePasscode' | translate}}
    </h2>

    <button
      type="button"
      class="close-btn p-0 m-n2"
      data-automation-id="change-passcode-dialog-close-button"
      [attr.aria-label]="'closeDialog' | translate"
      (click)="onClose()"
    >
      <span aria-hidden="true" class="icon icon-close"></span>
    </button>
  </div>

  <div class="modal-body">
    <form id="change-passcode-form" [formGroup]="changePasscodeForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label
          class="form-control-label"
          for="change-passcode-form-current-passcode"
          data-automation-id="change-passcode-dialog-current-passcode-label"
        >
          {{'currentPasscode' | translate}}
        </label>

        <app-passcode-visibility-toggler>
          <input
            type="password"
            id="change-passcode-form-current-passcode"
            class="form-control"
            formControlName="currentPasscode"
            autocomplete="current-password"
            data-automation-id="change-passcode-dialog-current-passcode-input"
            [attr.aria-required]="true"
            [attr.aria-invalid]="currentPasscode.touched && currentPasscode.invalid"
            [class.is-invalid]="currentPasscode.touched && currentPasscode.invalid"
            [attr.aria-describedby]="currentPasscode.touched && currentPasscode.invalid ? 'change-passcode-form-current-passcode-error' : null"
          >
        </app-passcode-visibility-toggler>

        <div class="invalid-feedback d-block" role="alert" aria-live="assertive" id="change-passcode-form-current-passcode-error">
          <div
            *ngIf="currentPasscode.touched && currentPasscode.invalid"
            data-automation-id="change-passcode-dialog-current-passcode-errors-container"
          >
            <div
              *ngIf="currentPasscode.errors.required"
              class="error-message"
              data-automation-id="change-passcode-dialog-current-passcode-required-error"
            >
              {{'validationRequired' | translate}}
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="passcodePolicyMessage"
        class="policy-message p-4 my-4 text-center"
        role="note"
        data-automation-id="change-passcode-dialog-policy-message"
      >
        {{passcodePolicyMessage}}
      </div>

      <div class="form-group">
        <label
          class="form-control-label"
          for="change-passcode-form-new-passcode"
          data-automation-id="change-passcode-dialog-new-passcode-label"
          [attr.aria-describedby]="newPasscode.touched && newPasscode.invalid ? 'change-passcode-form-new-passcode-error' : null"
        >
          {{'newPasscode' | translate}}
        </label>

        <app-passcode-visibility-toggler>
          <input
            type="password"
            id="change-passcode-form-new-passcode"
            class="form-control"
            formControlName="newPasscode"
            autocomplete="new-password"
            data-automation-id="change-passcode-dialog-new-passcode-input"
            [attr.aria-required]="true"
            [attr.aria-invalid]="newPasscode.touched && newPasscode.invalid"
            [class.is-invalid]="newPasscode.touched && newPasscode.invalid"
            [attr.aria-describedby]="newPasscode.touched && newPasscode.invalid ? 'change-passcode-form-new-passcode-error' : null"
          >
        </app-passcode-visibility-toggler>

        <div class="invalid-feedback d-block" role="alert" aria-live="assertive" id="change-passcode-form-new-passcode-error">
          <div
            *ngIf="newPasscode.touched && newPasscode.invalid"
            data-automation-id="change-passcode-dialog-new-passcode-errors-container"
          >
            <div
              *ngIf="newPasscode.errors.required"
              class="error-message"
              data-automation-id="change-passcode-dialog-new-passcode-required-error"
            >
              {{'validationRequired' | translate}}
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label
          class="form-control-label"
          for="change-passcode-form-confirm-new-passcode"
          data-automation-id="change-passcode-dialog-confirm-new-passcode-label"
        >
          {{'confirmNewPasscode' | translate}}
        </label>

        <app-passcode-visibility-toggler>
          <input
            type="password"
            id="change-passcode-form-confirm-new-passcode"
            class="form-control"
            formControlName="confirmNewPasscode"
            autocomplete="new-password"
            data-automation-id="change-passcode-dialog-confirm-new-passcode-input"
            [attr.aria-required]="true"
            [attr.aria-invalid]="confirmNewPasscode.touched && confirmNewPasscode.invalid"
            [class.is-invalid]="confirmNewPasscode.touched && confirmNewPasscode.invalid"
            [attr.aria-describedby]="confirmNewPasscode.touched && confirmNewPasscode.invalid ? 'change-passcode-form-confirm-new-passcode-error' : null"
          >
        </app-passcode-visibility-toggler>

        <div class="invalid-feedback d-block" role="alert" aria-live="assertive" id="change-passcode-form-confirm-new-passcode-error">
          <div
            *ngIf="confirmNewPasscode.touched && confirmNewPasscode.invalid"
            data-automation-id="change-passcode-dialog-confirm-new-passcode-errors-container"
          >
            <div
              *ngIf="confirmNewPasscode.errors.required"
              class="error-message"
              data-automation-id="change-passcode-dialog-confirm-new-passcode-required-error"
            >
              {{'validationRequired' | translate}}
            </div>

            <div
              *ngIf="confirmNewPasscode.errors.match"
              class="error-message"
              data-automation-id="change-passcode-dialog-confirm-new-passcode-match-error"
            >
              {{'passcodesMatchValidation' | translate}}
            </div>
          </div>
        </div>

        <div *ngIf="serverError" class="server-error-message" data-automation-id="change-passcode-server-error">
          {{ serverError | translate }}
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer justify-content-start">
    <button
      type="submit"
      form="change-passcode-form"
      class="ins-buttons button-primary flint"
      data-automation-id="change-passcode-dialog-submit-button"
    >
      {{'changePasscode' | translate}}
      <div
        *ngIf="passcodeUpdateLoading"
        class="ml-2 spinner-border passcode-update-loading-spinner"
        data-automation-id="passcode-update-loading">
        <span class="sr-only" translate>Loading...</span>
      </div>
    </button>

    <button
      class="ins-buttons button-secondary flint ml-3"
      data-automation-id="change-passcode-dialog-cancel-button"
      (click)="onClose()"
    >
      {{'cancelButton' | translate}}
    </button>
  </div>
</div>
